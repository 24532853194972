<template>
  <div class='page_search'>
    <div class="search_container">
      <p class="search_result">为你找到 {{total}} 个关于 <b class="word_style">{{word}}</b> 的课程</p>

      <div class="course_content">
        <div v-if="course_list.length" class="course_box">
          <course_item :course_item="item" v-for="(item, index) in course_list" :key="index" :word="word" />
        </div>
        <div v-else class="nocontent">
          <img class="ma" src="@/assets/common/nocontent.png" mode="widthFix" />
          <p>暂无内容</p>
        </div>

      </div>

      <div class="page_wrap">
        <el-pagination background :pager-count="5" :page-size="limit" :total="total" :current-page="page" @prev-click="prev_click" @next-click="next_click" @current-change="current_change" :hide-on-single-page="true"
          layout="total, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import course_item from "@/components/course_item.vue";
import api from "@/config/api";
export default {
  components: { course_item },
  data() {
    return {
      word: "",
      page: 1,
      limit: 16,
      total: null,
      course_list: [],
    };
  },
  created() {},
  mounted() {
    let word = this.$route.params.word;
    this.word = word.replaceAll("%2f","/");
    this.getData();

    this.$bus.$on("handleWord",  (word)=> {
      if (word) {
        this.word = word.replaceAll("%2f","/");
        this.page = 1
        this.getData();
      }
    });
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    async getData() {
      let res = await api.search({
        page: this.page,
        limit: this.limit,
        word: this.word,
      });
      if (res.code == 0) {
        let data = res.result;
        this.course_list = data.data;
        this.total = Number(data.count);
      } else {
        this.$message(res.message);
      }
    },
    prev_click(e) {
      this.page = e;
      this.getData();
    },
    next_click(e) {
      this.page = e;
      this.getData();
    },
    current_change(e) {
      this.page = e;
      this.getData();
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.word_style {
  color: #409eff;
}
.page_search {
  // min-height: calc(100vh - 451px);
  min-height: 100vh;
  background-color: #f9fbfd;
  padding-bottom: 25px;
}
.search_container {
  width: 1200px;
  margin: 0 auto;
}
.search_result {
  font-size: 28px;
  font-weight: 600;
  color: #2d2d2d;
  padding-top: 20px;
  text-align: left;
}
.course_content {
  // min-height: 400px;
  min-height: 700px;
  position: relative;
}
.course_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
}
.course_item {
  margin-right: 24px;
  margin-bottom: 30px;
}
.course_item:nth-of-type(4n) {
  margin-right: 0;
}
.page_wrap {
  margin-bottom: 15px;
}
</style>